import React from 'react';
import { Button, Grid } from '@mui/material';
import './appFooter.css';
import CopyrightIcon from '@mui/icons-material/Copyright';

interface IAppFooter {
  showDialog: (name: string) => void
}

export default function AppFooter(props: IAppFooter) {

  return (
    <div className="app-footer noprint">
      <Grid container alignItems="center" alignContent="flex-end">
        <Grid item md={6} xs={12}>
          <span className="app-footer-copyright"><CopyrightIcon className="app-footer-icon" />Copyright 2022-2025 by Tracy Fletcher. All Rights Reserved.</span>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button id="app-footer-contact-us" style={{ textTransform: 'none', color: 'black' }} onClick={(e: any) => props.showDialog('Contact Us')}>Contact Us</Button>
        </Grid>
      </Grid>
    </div>
  )
}
