import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import store from './redux/store';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cc9966',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#c6a07a',
      // light: will be calculated from palette.secondary.main,
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated from palette.secondary.main,
    },
  },
});

export default function MyApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<App />} />
          {/*
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
          */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Auth0Provider
        domain={ window.location.hostname === 'localhost' ? 'dev-0fkxkx5f.us.auth0.com' : 'tfletservices.us.auth0.com' }
        clientId={ window.location.hostname === 'localhost' ? 'ryCXFBlgFrM4OS39ed5ydzsKhSO26XZ5' : 'tNDuhs11bu33pqXZNMjDw3AK4EZGdNFj' }
        authorizationParams={{ redirect_uri: window.location.origin }}
        >
          <Provider store={store}>
            <App />
          </Provider>
      </Auth0Provider>
    </BrowserRouter>
  </ThemeProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
