import _ from 'lodash';

export function validateDogFields(updatedFormValidation: any, fieldName: string, value: string) {
  const keys = Object.keys(updatedFormValidation);
  if (!keys.includes(fieldName)) {
    return updatedFormValidation;
  }
  updatedFormValidation[`${fieldName}`].isWarning = false;
  updatedFormValidation[`${fieldName}`].warningMsg = '';
  updatedFormValidation[`${fieldName}`].isError = false;
  updatedFormValidation[`${fieldName}`].errorMsg = '';
  if (value === '') {
    if (['akcName', 'owners', 'callName', 'sex', 'breed'].includes(fieldName)) {
      updatedFormValidation[`${fieldName}`].isError = true;
      updatedFormValidation[`${fieldName}`].errorMsg = 'required';
    }
    return updatedFormValidation;
  }

  if (fieldName === 'owners' || fieldName === 'breeder' || fieldName === 'handler') {
    setNotMixedCaseWarning(updatedFormValidation, fieldName, value, 3);
  }
  if (fieldName === 'countryOfBirth') {
    setNotMixedCaseWarning(updatedFormValidation, fieldName, value, 4);
  }

  if (fieldName === 'akcName' || fieldName === 'sire' || fieldName === 'dam') {
    setNotMixedCaseWarning(updatedFormValidation, fieldName, value, 15);
  }
  if (fieldName === 'dateOfBirth') {
    if (value.match(/^[0-1][0-9]\/[0-3][0-9]\/\d{4}$/)) {
      const birthYear = parseInt(value.substring(6));
      const currentYear = new Date().getFullYear();
      const birthDateObj = new Date(value);
      const todayDateObj = new Date();
      const ageInYears = (todayDateObj.getTime() - birthDateObj.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
      if (birthYear > currentYear) {
        updatedFormValidation.dateOfBirth.isWarning = true;
        updatedFormValidation.dateOfBirth.warningMsg = `birth year should not be in the future`;
      } else if (ageInYears < 1) {
        updatedFormValidation.dateOfBirth.isWarning = true;
        updatedFormValidation.dateOfBirth.warningMsg = `birth year makes your dog less than 1 year old`;
      } else if (ageInYears > 13) {
        updatedFormValidation.dateOfBirth.isWarning = true;
        updatedFormValidation.dateOfBirth.warningMsg = `birth year makes your dog more than ${Math.floor(ageInYears)} years old`;
      }
    } else {
      updatedFormValidation.dateOfBirth.isError = true;
      updatedFormValidation.dateOfBirth.errorMsg = 'expect MM/DD/YYYY';
    }
  }
  return updatedFormValidation;
}

export function validateDogBreedAkcNumFields(
  updatedFormValidation: any,
  fieldName: string,
  value: string,
  akcRegistrationNum: string,
  breed: string,
  breedGroups: any[],
  akcRegistrationPrefixes: any[]
) {
  const keys = Object.keys(updatedFormValidation);
  if (!keys.includes(fieldName)) {
    return updatedFormValidation;
  }
  updatedFormValidation[`${fieldName}`].isWarning = false;
  updatedFormValidation[`${fieldName}`].warningMsg = '';
  updatedFormValidation[`${fieldName}`].isError = false;
  updatedFormValidation[`${fieldName}`].errorMsg = '';
  if (value === '') {
    updatedFormValidation[`${fieldName}`].isError = true;
    updatedFormValidation[`${fieldName}`].errorMsg = 'required';
    return updatedFormValidation;
  }

  if (fieldName === 'breed') {
    if (akcRegistrationNum !== '') {
      updatedFormValidation.akcRegistrationNum.isWarning = false;
      updatedFormValidation.akcRegistrationNum.isError = false;
      const firstChars = checkRegistrationPrefix(value, akcRegistrationNum, breedGroups, akcRegistrationPrefixes);
      if (!firstChars.isValid) {
        updatedFormValidation.akcRegistrationNum.isWarning = true;
        updatedFormValidation.akcRegistrationNum.warningMsg = firstChars.msg;
      }
    }
  }
  if (fieldName === 'akcRegistrationNum') {
    if (!value.match(/^[A-Z]{2}.*|^ILP.*|^PAL.*/)) {
      updatedFormValidation.akcRegistrationNum.errorMsg = '';
      updatedFormValidation.akcRegistrationNum.isWarning = true;
      updatedFormValidation.akcRegistrationNum.warningMsg = 'expect to start with two capital letters or ILP or PAL';
    } else if (value.match(/^[A-Z]{2}[0-9]{8}$/) || value.match(/^MA[0-9]{8}$/) || value.match(/^ILP[0-9]{4}$/) || value.match(/^PAL[0-9]{6}$/)) {
      if (breed) {
        const firstChars = checkRegistrationPrefix(breed, value, breedGroups, akcRegistrationPrefixes);
        if (!firstChars.isValid) {
          updatedFormValidation.akcRegistrationNum.isWarning = true;
          updatedFormValidation.akcRegistrationNum.warningMsg = firstChars.msg;
        }
      }
      if (value.startsWith('MA') && !value.endsWith('01')) {
        updatedFormValidation.akcRegistrationNum.isWarning = true;
        updatedFormValidation.akcRegistrationNum.warningMsg = 'MA registrations typically have 01 as last two digits';
      }
      if (!value.startsWith('ILP')) {
        const puppyWithinLitter = parseInt(value.substring(8));
        // TODO: add breed-based liter size, Labs might be 10 while papillons might be 4
        if (puppyWithinLitter > 10) {
          updatedFormValidation.akcRegistrationNum.isWarning = true;
          updatedFormValidation.akcRegistrationNum.warningMsg = 'the last two digits is higher than expected';
        }
      }
    } else {
      updatedFormValidation.akcRegistrationNum.isWarning = true;
      if (value.startsWith('MA')) {
        updatedFormValidation.akcRegistrationNum.warningMsg = 'expect MA to be followed by 8 numbers';
      } else if (value.startsWith('ILP')) {
        updatedFormValidation.akcRegistrationNum.warningMsg = 'expect ILP to be followed by 4 numbers';
      } else {
        updatedFormValidation.akcRegistrationNum.warningMsg = 'expect two capital letters to be followed by 8 numbers';
      }
    }
  }
  return updatedFormValidation;
}

export function validateDogJrHandlerFields(updatedFormValidation: any, fieldName: string, value: string, jrHandlerNumber: string, jrHandlerName: string) {
  const keys = Object.keys(updatedFormValidation);
  if (!keys.includes(fieldName)) {
    return updatedFormValidation;
  }
  ['jrHandlerName', 'jrHandlerNumber'].forEach((name: string) => {
    updatedFormValidation[`${name}`].isWarning = false;
    updatedFormValidation[`${name}`].warningMsg = '';
    updatedFormValidation[`${name}`].isError = false;
    updatedFormValidation[`${name}`].errorMsg = '';
  });

  if (fieldName === 'jrHandlerName') {
    setNotMixedCaseWarning(updatedFormValidation, fieldName, value, 3);
  }

  if (jrHandlerName !== '' && jrHandlerNumber === '') {
    updatedFormValidation.jrHandlerNumber.isWarning = true;
    updatedFormValidation.jrHandlerNumber.warningMsg = 'value needed when Name is specified';
  }
  if (jrHandlerName === '' && jrHandlerNumber !== '') {
    updatedFormValidation.jrHandlerName.isWarning = true;
    updatedFormValidation.jrHandlerName.warningMsg = 'value needed when Number is specified';
  }
  return updatedFormValidation;
}

export function validateOwnerAddressFields(updatedFormValidation: any, fieldName: string, value: string) {
  const keys = Object.keys(updatedFormValidation);
  if (!keys.includes(fieldName)) {
    return updatedFormValidation;
  }
  updatedFormValidation[`${fieldName}`].isWarning = false;
  updatedFormValidation[`${fieldName}`].warningMsg = '';
  updatedFormValidation[`${fieldName}`].isError = false;
  updatedFormValidation[`${fieldName}`].errorMsg = '';
  if (value === '') {
    updatedFormValidation[`${fieldName}`].isError = true;
    updatedFormValidation[`${fieldName}`].errorMsg = 'required';
    return updatedFormValidation;
  }
  if (fieldName === 'first' || fieldName === 'last' || fieldName === 'city' || fieldName === 'street') {
    setNotMixedCaseWarning(updatedFormValidation, fieldName, value, 3);
  }
  if (fieldName === 'zip') {
    if (value !== '' && !value.match(/^\d{5}(-\d{4})?$/)) {
      updatedFormValidation.zip.isWarning = true;
      updatedFormValidation.zip.warningMsg = 'expect ##### or #####-#### format';
    }
  }
  if (fieldName === 'phone') {
    if (!value.match(/^\d{3}-\d{3}-\d{4}$/)) {
      updatedFormValidation.phone.isWarning = true;
      updatedFormValidation.phone.warningMsg = 'expect ###-###-####';
    }
  }
  if (fieldName === 'email') {
    updatedFormValidation.email.isError = false;
    if (!/^[A-Za-z0-9-_+.]{1,64}@[A-Za-z0-9-_.]{1,256}$/.test(value)) {
      updatedFormValidation.email.isError = true;
      updatedFormValidation.email.errorMsg = 'Invalid email format';
    } else if (isAllUpperCase(value, 3)) {
      updatedFormValidation.email.isWarning = true;
      updatedFormValidation.email.warningMsg = 'Expected lower-case or mixed-case, not all upper-case';
    } else if (!/\.com$|\.net$|\.edu$|\.gov$|\.org$|\.info$/.test(value)) {
      updatedFormValidation.email.isWarning = true;
      updatedFormValidation.email.warningMsg = 'Unusual suffix, expect .com, .net, .edu, .gov, .org, .info';
    }
  }
  return updatedFormValidation;
}

export function validateClassesEntered(updatedFormValidation: any, classesEntered: string[]) {
  const keys = Object.keys(updatedFormValidation);
  if (!keys.includes('classesEntered') || classesEntered.length === 0) {
    return updatedFormValidation;
  }
  updatedFormValidation.classesEntered.isWarning = false;
  updatedFormValidation.classesEntered.warningMsg = '';

  if (
    classesEntered.filter((val) => val.match(/^Rally Novice (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Rally Advanced (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Rally Excellent (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Beginner Novice (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Obed Novice (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Open (A|B)$/)).length > 1 ||
    classesEntered.filter((val) => val.match(/^Utility (A|B)$/)).length > 1
  ) {
    updatedFormValidation.classesEntered.isWarning = true;
    updatedFormValidation.classesEntered.warningMsg = 'Invalid classes selected. Cannot enter A and B at same level.';
  }
  if (
    classesEntered.includes('Rally Master') &&
    (classesEntered.filter((val) => val.match(/^Rally Novice (A|B)$/)).length > 0 ||
      classesEntered.filter((val) => val.match(/ A$/)).length > 0 ||
      classesEntered.filter((val) => val.match(/^Rally Intermediate$/)).length > 0)
  ) {
    updatedFormValidation.classesEntered.isWarning = true;
    updatedFormValidation.classesEntered.warningMsg = 'Invalid classes selected';
  }
  if (classesEntered.includes('Utility B') && (classesEntered.filter((val) => val.match(/^Novice/)).length > 0 || classesEntered.includes('Open A'))) {
    updatedFormValidation.classesEntered.isWarning = true;
    updatedFormValidation.classesEntered.warningMsg = 'Invalid classes selected';
  }
  return updatedFormValidation;
}

function checkRegistrationPrefix(breed: string, regNum: string, breedGroups: any[], akcRegistrationPrefixes: any[]) {
  const regPrefix = regNum.substring(0, 2);

  if (breed.includes('All American') && regPrefix.indexOf('MA') === -1) {
    return { isValid: false, msg: `typically expect ${breed} registration numbers to start with MA` };
  }
  if (regNum.startsWith('ILP') || regNum.startsWith('PAL')) {
    // TODO: confirm it is reasonable to put in any breed for these regs
    return { isValid: true, msg: '' };
  }
  if (breed.includes('Poodle') && regPrefix.indexOf('PR') === -1) {
    return { isValid: false, msg: `typically expect ${breed} registration numbers to start with PR` };
  }
  if (!breed.includes('Poodle')) {
    const breedGroupsIndex = _.findIndex(breedGroups, (val) => val.name === breed);
    const group = _.get(breedGroups[breedGroupsIndex], 'group', 'unknown');
    const akcRegistrationPrefixesIndex = _.findIndex(akcRegistrationPrefixes, (val) => val.group === group);
    if (akcRegistrationPrefixesIndex > -1) {
      const expectedPrefix = akcRegistrationPrefixes[akcRegistrationPrefixesIndex].prefix;
      if (!expectedPrefix.includes(regPrefix)) {
        return {
          isValid: false,
          msg: `typically expect ${breed} registration numbers to start with ${expectedPrefix.toString().replace(/,/g, ' or ')}`
        };
      }
    }
  }
  return { isValid: true, msg: '' };
}

function setNotMixedCaseWarning(updatedFormValidation: any, fieldName: string, value: string, threshold: number) {
  if (value === undefined) {
    return;
  }
  const isAllUpperCase = value === value.toUpperCase();
  const isAllLowerCase = value === value.toLowerCase();
  if (value.length > threshold && (isAllLowerCase || isAllUpperCase)) {
    updatedFormValidation[`${fieldName}`].isWarning = true;
    updatedFormValidation[`${fieldName}`].warningMsg = `Expected mixed-case, not all ${isAllLowerCase ? 'lower' : 'upper'}-case`;
  }
}

function isAllUpperCase(value: string, threshold: number) {
  const isAllUpperCase = value === value.toUpperCase();
  if (value.length > threshold && isAllUpperCase) {
    return true;
  }
  return false;
}
