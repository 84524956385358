import React, { useEffect, useState } from 'react';
import {AppBar, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setIsAuth0Authenticated, setRegistrant, setJwtToken } from '../../../redux/reducers/registrantSlice';
import { setUserProfile } from '../../../redux/reducers/userProfileSlice';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './headerMenuBar.css';
import _ from 'lodash';
import { UserRecord } from '../../../models/UserRecord';

export default function HeaderMenuBar() {

  const { firstName, lastName, email, isAuth } = useAppSelector((state: any) => state.registrant);
  const { isEventAdmin, ownerAddress } = useAppSelector((state: any) => state.userProfile);
  const dispatch = useAppDispatch();

  const [ anchorElNav, setAnchorElNav ] = useState<null | HTMLElement>(null);
  const [ anchorElUser, setAnchorElUser ] = useState<null | HTMLElement>(null);
  const { loginWithPopup, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  
  useEffect(() => {
    if (_.get(ownerAddress, 'first', '') !== '') {
      dispatch(setRegistrant({
        firstName: ownerAddress.first,
        lastName: ownerAddress.last
      }));
    }
    if (_.get(user, 'name', '') !== '') {
      const myEmail = email !== '' ? email : _.get(user, 'email', '').trim();
      dispatch(setRegistrant({
        email: myEmail,
        isAdmin: true,
        isAuth: isAuthenticated,
        isAuth0Authenticated: isAuthenticated
      }));
    }
  });

  useEffect(() => {
    async function fetchData() {
      let token: string|undefined = '';
      const getTokenOptions = {
        authorizationParams: {
          audience: 'https://events/api',
          scope: 'read:profile',
        }
      }
      try {
        token = await getAccessTokenSilently(getTokenOptions);
      } catch(e: any) {
        if (e.error === 'consent_required') {
          token = await getAccessTokenWithPopup(getTokenOptions);
        }
      }
      dispatch(setJwtToken(token ? token : ''));

      try {
        const userRsp = await axios.get(`/api/protected/user/${ _.get(user, 'email', '')}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          }
        });
        if (userRsp.data === null || _.get(userRsp, 'data.ok') === false) {
          const userRecord: UserRecord = {
            _id: _.get(user, 'email',''), 
            createdDate: new Date().toISOString(),
            updatedDate: new Date().toISOString(),
            events: [],
            dogs: [],
            isEventAdmin: false,
            ownerAddress: {
              first: '',
              last: '',
              street: '',
              city: '',
              state: '',
              zip: '',
              phone: '',
              email: _.get(user, 'email','')
            }
          }
          await axios.post(`/api/protected/user/${userRecord._id}`, userRecord, { headers: { Authorization: `Bearer ${token}`}})
        }
        dispatch(setUserProfile({
          events: userRsp.data.events,
          ownerAddress: userRsp.data.ownerAddress,
          dogs: userRsp.data.dogs,
          isEventAdmin: userRsp.data.isEventAdmin
        }));  
      } catch(err: any)  {
        console.log('DEBUG: user get/post error=' + JSON.stringify(err, null, 2));
      }
    }
    if (isAuthenticated && user) {
      const myEmail = email !== '' ? email : _.get(user, 'email', '').trim();
      dispatch(setRegistrant({
        email: myEmail,
        isAuth: isAuthenticated,
        isAuth0Authenticated: isAuthenticated
      }));
      fetchData();
    }
  }, [user, isAuthenticated, dispatch, getAccessTokenSilently, email, getAccessTokenWithPopup]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const updateAu0Authenticated = (dispatch: any, val: boolean) => {
    return new Promise<void>((resolve) => {
      dispatch(setIsAuth0Authenticated(val));
      resolve();
    })
  }

  const handleOnClickUserLogout = () => {
    updateAu0Authenticated(dispatch, false).then(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
      dispatch(setRegistrant({
        firstName: '',
        lastName: '',
        email: '',
        isAuth: false,
        isAuthenticated: false,
        isAdmin: false,
        allowCookies: false,
        jwtToken: ''
      }));
    });
    setAnchorElUser(null);
  }

  const handleLogin = async (event: any) => {
    event.preventDefault();
    handleCloseUserMenu();
    try {
      await loginWithPopup(); 
     } catch(e) {
      console.log('login cancelled/closed')
    }
  }

  return (
    <AppBar position="sticky" color="primary" className="noprint">
      <Container maxWidth="xl"  className="app-header-menu-bar">
        <Toolbar disableGutters>
          <Link style={{textTransform: 'none'}} href="/" underline="none">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, color: 'white', display: { xs: 'none', md: 'flex' } }}
            >
              Tracy Fletcher Dog Event Services
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Link
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{textTransform: 'none', fontSize: '1em' }}
                href="/products"
                underline="none"
              >
                Products
            </Link>
          </Box>
          { isAuth && isEventAdmin ?
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link
                  id="header-menu-bar-configuration"
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style={{textTransform: 'none', fontSize: '1em'}}
                  href="/configurations"
                  underline="none"
                >
                  Configurations
              </Link>
            </Box>
            : null
          }
          <Box sx={{ flexGrow: 0 }}>
            { isAuth ?
              <>
                <Tooltip title="Open settings">
                <Button
                  key='login-button'
                  onClick={handleOpenUserMenu}
                  style={{textTransform: 'none', fontSize: '1em'}}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  { `${firstName} ${lastName}` }
                </Button>
                  {/*
                  <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Unknown" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                  */}
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  style={{textTransform: 'none'}}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link href="/userProfile" style={{ textDecoration: 'none' }} underline='none'>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">My Profile</Typography>
                    </MenuItem>
                  </Link>
                  
                  <MenuItem onClick={() => handleOnClickUserLogout()}>
                    <Typography textAlign="center">Log Out</Typography>
                  </MenuItem>
                </Menu>
              </>
              : 
              <>
                <Button
                  id="header-menu-bar-login"
                  key='login-button'
                  style={{textTransform: 'none'}}
                  onClick={(e: any) => { handleLogin(e) }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Log In
                </Button>
              </>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
